import React, {useState} from 'react';
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import FormCreditCard from "../components/form-credit-card";
import {useHistory} from "react-router-dom";
import {useMerchantState} from "../context/merchant-context";
import MainLayout from "../layout/main-layout";
import {getUrlParams} from "../libs/utils";
import ButtonPay from "../components/button-pay";

const PagePaymentForm = () => {

    const merchant = useMerchantState();
    const [validForm, setValidForm] = useState();
    const [clover, setCloverInstance] = useState();
    const history = useHistory();
    const {merchant: merchantId, payment: paymentId, customer: customerId} = getUrlParams();

    if (!merchantId || !paymentId || !customerId) {
        throw Error('Invalid query params!');
    }

    return (
        <MainLayout>
            <Header/>
            <SubHeader merchant={merchant}/>
            <FormCreditCard
                setValidForm={setValidForm}
                setCloverInstance={setCloverInstance}
                paymentId={paymentId}
                merchantId={merchantId}
                customerId={customerId}
                history={history}
                eCommerceKey={merchant.eCommerceKey}/>
            <ButtonPay
                merchantId={merchantId}
                paymentId={paymentId}
                customerId={customerId}
                clover={clover}
                validForm={validForm}
            />
        </MainLayout>
    );
};

export default PagePaymentForm;
