import React from 'react'
import {getMerchantDetails} from "../services/merchant-service";

const defaultState = {
    id: null,
    name: null,
    owner: null,
    email: null,
    logo: null,
    phone: null,
    eCommerceKey: null
};

const MerchantStateContext = React.createContext();
const MerchantDispatchContext = React.createContext();

function merchantReducer(state, {type, payload}) {
    switch (type) {
        case 'setMerchant': {
            return Object.assign({}, state, payload);
        }
        case 'setMerchantId': {
            return {...state, id: payload.id};
        }
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }
    }
}

function MerchantProvider({children}) {

    const [state, dispatch] = React.useReducer(merchantReducer, {...defaultState})
    return (
        <MerchantStateContext.Provider value={state}>
            <MerchantDispatchContext.Provider value={dispatch}>
                {children}
            </MerchantDispatchContext.Provider>
        </MerchantStateContext.Provider>
    )
}

function useMerchantState() {

    const context = React.useContext(MerchantStateContext)
    if (context === undefined) {
        throw new Error('useMerchantState must be used within a MerchantProvider')
    }
    return context
}

function useMerchantDispatch() {
    const context = React.useContext(MerchantDispatchContext)
    if (context === undefined) {
        throw new Error('useMerchantDispatch must be used within a CountProvider')
    }
    return context
}

async function getMerchant(dispatch, merchantId) {
    try {
        const merchant = await getMerchantDetails(merchantId);
        dispatch({type: 'setMerchant', payload: merchant})
    } catch (error) {
        dispatch({type: 'failGetMerchant', error})
    }
}

function setMerchantId(dispatch, merchantId) {
    dispatch({type: 'setMerchantId', payload: merchantId});
    localStorage.setItem('merchantId', merchantId);
}

export {MerchantProvider, useMerchantDispatch, useMerchantState, getMerchant, setMerchantId}
