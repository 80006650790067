import {useState} from 'react';

export const useOrder = () => {

    const [order, setOrder] = useState({});

    return {
        order,
        setOrder
    }
};


