import {useState} from 'react';

const defaultCustomer = {
    firstName: '',
    lastName: '',
    address1: '',
    city: '',
    zip: '',
    state: '',
    country: 'us'
};
export const useCustomer = () => {

    const [customer, setCustomer] = useState(defaultCustomer);

    const setCustomerProp = (prop, val) => {
        setCustomer(Object.assign({}, customer, {[prop]: val}));
    }

    const isValidCustomerInfo = () => {
        const notValid =  Object.entries(customer)
            .filter(([, value]) => value === '')
            .length > 0;
        return notValid === false;
    };

    return {
        customer,
        setCustomer,
        setCustomerProp,
        isValidCustomerInfo
    }
};


