import React, {useState} from 'react';
import {payBill} from "../services/pay-service";
import {sendReceipt} from "../services/billing-service";
import {useHistory} from "react-router-dom";
import Spinner from "./spinner";

const ButtonPay = ({validForm, clover, merchantId, paymentId, customerId}) => {

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();
    const handlePayNow = async () => {

        if (!validForm) {
            console.warn('Payment form is invalid!');
            return;
        }

        setProcessing(true);
        const {errors, card, token} = await clover.createToken();
        if (errors) {
            console.error(errors);
            setProcessing(false);
            return;
        }


        try {
            setError(false);
            await payBill({merchantId, paymentId}, {card, token, customerId});
            await sendReceipt(merchantId, paymentId)
                .catch((err) => {
                    console.error(err);
                });
            const url = `/billing/complete?customer=${customerId}&payment=${paymentId}&merchant=${merchantId}`;
            history.replace(url);
        } catch (err) {
            console.error(err);
            setError(true);
            setProcessing(false);
        }

    }

    return (
        <div className="py-4 text-xl text-center">
            {
                processing ?
                    <button
                        disabled=""
                        className="w-full inline-flex items-center justify-center 'bg-gray-400'r py-1 px-3 border rounded">
                        <Spinner/>
                    </button>
                    :
                    <button
                        onClick={handlePayNow}
                        disabled={validForm !== true}
                        className={`w-full ${validForm ? 'bg-green-clover' : 'bg-gray-400'}  text-white font-semibold  py-1 px-3 border rounded`}>
                        Pay Now {validForm}
                    </button>
            }
            {error ? <div className="mt-5 mb-2 py-1 px-3 border border-red-500 text-red-500">
                Payment failed. Please make sure your credit card information is valid. Try again or use another card if issue persist.
            </div> : null}
        </div>
    )
};

export default ButtonPay;
