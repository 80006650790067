import React, {useEffect} from 'react';
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import FormCustomerInfo from "../components/form-customer-info";
import ButtonEnterCard from "../components/button-enter-card";
import {useMerchantState} from "../context/merchant-context";
import MainLayout from "../layout/main-layout";
import {getUrlParams} from "../libs/utils";
import {getCustomerDetails} from "../services/customer-service";
import {useCustomer} from "../hooks/use-customer";
import {getBillingDetails} from "../services/billing-service";
import {useBilling} from "../hooks/use-billing";

const PageCustomerInfo = () => {

    const merchant = useMerchantState();
    const {customer, setCustomer, setCustomerProp, isValidCustomerInfo} = useCustomer();
    const {merchant: merchantId, customer: customerId, payment: paymentId} = getUrlParams();
    const {billing, setBilling} = useBilling();

    useEffect(() => {

        let isSubscribed = true
        const fetchCustomer = async () => {
            const {id, firstName, lastName, addresses} = await getCustomerDetails({merchantId, customerId});
            let address = {};
            if (addresses.elements.length > 0) {
                const {address1, city, country, state, zip} = addresses.elements[0];
                address = {address1, city, country, state, zip};
            }
            if (isSubscribed) {
                setCustomer(Object.assign({}, customer, {id, firstName, lastName, ...address}));
            }
        };

        const fetchBilling = async () => {
            const billingDetails = await getBillingDetails({merchantId, billingId: paymentId});
            setBilling(billingDetails);
        }

        fetchBilling();
        fetchCustomer();
        return () => isSubscribed = false

        /* eslint-disable-next-line */
    }, []);

    const handleInputChange = (prop, event) => {
        setCustomerProp(prop, event.target.value)
    };

    return <MainLayout>
        <Header/>
        <SubHeader merchant={merchant}/>
        <FormCustomerInfo
            handleInputChange={handleInputChange}
            customer={customer}/>
        {customer.id ?
            <ButtonEnterCard
                recurring={billing.is_recurring}
                validCustomer={isValidCustomerInfo()}
                customer={customer}
                merchantId={merchantId}
                paymentId={paymentId}/> :
            null
        }
    </MainLayout>
}


export default PageCustomerInfo;
