import React, {useEffect, useState} from 'react';
import MainLayout from "../layout/main-layout";
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import {useMerchantState} from "../context/merchant-context";
import {useOrder} from "../hooks/use-order";
import {useBilling} from "../hooks/use-billing";
import {getUrlParams} from "../libs/utils";
import {useParams} from "react-router-dom";
import InvoiceDetails from "../components/invoice-details";
import Spinner from "../components/spinner";
import {getOrderDetails} from "../services/order-service";
import {getBillingDetails} from "../services/billing-service";
import ButtonInvoice from "../components/button-invoice";
import ButtonCancelRecurring from "../components/button-cancel-recurring";

const PageRecurringCancel = () => {

    const {...merchant} = useMerchantState();
    const {order, setOrder} = useOrder({});
    const {billing, setBilling} = useBilling({});
    const [cancelled, setCancelled] = useState(false);
    const {merchant: merchantId, order: orderId, customer: customerId} = getUrlParams();
    const params = useParams();
    if (!params.paymentId) {
        // TODO: do something here! return to page not found / payment not found
    }

    const getBilling = async () => {
        const billingDetails = await getBillingDetails({merchantId, billingId: params.paymentId});
        setBilling(billingDetails);
    };

    const getOrder = async () => {
        const orderDetails = await getOrderDetails({merchantId, orderId});
        setOrder(orderDetails);
    };

    const loadInfo = async () => {
        await getOrder();
        await getBilling();
    };

    useEffect(() => {
        loadInfo();
        /* eslint-disable-next-line */
    }, []);


    // for reloading after cancelled
    useEffect(() => {
        if (cancelled === true) {
            getBilling();
        }
        /* eslint-disable-next-line */
    }, [cancelled]);

    return <MainLayout>
        <Header/>
        <SubHeader merchant={merchant}/>
        {billing.id ?
            <>
                <InvoiceDetails order={order} billingDetail={billing}/>
                {billing.status === 'cancelled' || billing.recurring_state === 'cancelled' ?
                    <div
                        className="flex flex-col items-center justify-center text-lg border border-gray-200 p-4 bg-yellow-400">
                        <div className="font-bold">ID: {billing.id}</div>
                        <div className="font-semibold">Payment request has been cancelled.</div>
                        <div>Please contact merchant for any questions.</div>
                        <div>
                            <a href={`mailto: ${merchant.email}`}>{merchant.email}</a>
                        </div>
                        <div>{merchant.phone}</div>
                    </div> :
                    <>
                        <ButtonInvoice orderId={orderId}/>
                        <ButtonCancelRecurring
                            setCancelled={setCancelled}
                            merchantId={merchantId}
                            paymentId={params.paymentId}
                            customerId={customerId}
                        />
                    </>
                }
            </> :
            <div className="w-full my-4 inline-flex justify-center"><Spinner/></div>
        }
    </MainLayout>
}

export default PageRecurringCancel;
