import http from '../libs/http';

export const getCustomerDetails = ({merchantId, customerId}) => {

    const endpoint = `/v3/merchants/${merchantId}/customers/${customerId}?expand=addresses,emailAddresses,phoneNumbers`;
    return http({endpoint});
};

export const updateCustomer = ({merchantId, customerId}, payload) => {

    const endpoint = `/v3/merchants/${merchantId}/customers/${customerId}`;
    return http({method: 'POST', endpoint, body: payload});
};

export const updateCustomerAddress = ({merchantId, customerId}, payload) => {

    const endpoint = `/v3/merchants/${merchantId}/customers/${customerId}/addresses`;
    return http({method: 'POST', endpoint, body: payload});
}

export const getSavedCard = (customerId, paymentId) => {
    const endpoint = `/api/customers/${customerId}/cards?paymentId=${paymentId}`;
    return http({method: 'GET', endpoint});
}

export const saveCustomerCard = (customerId, payload) => {
    const endpoint = `/api/customers/${customerId}/cards`;
    return http({method: 'POST', endpoint, body: payload});
}
