import React from 'react';

const SubHeader = ({merchant = {}}) => {

    return <div className="bg-gray-100 py-4 px-3">
        <div className="flex justify-between">
            <div className="self-center">
                <p className="text-xl my-0">{merchant.name}</p>
                <p>{merchant.phone}</p>
                <p>{merchant.email}</p>
            </div>
            {merchant.logo ?
                <div className="self-center" style={{height: '80px'}}>
                    <img alt="" className="logo" src={merchant.logo}/>
                </div>
                :
                null
            }
        </div>
    </div>
};

export default SubHeader;
