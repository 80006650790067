import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import PageSinglePayment from "./pages/page-single-payment";
import PageNotFound from "./pages/page-not-found";
import PageRecurringPayment from "./pages/page-recurring-payment";
import PageCustomerInfo from "./pages/page-customer-info";
import PagePaymentForm from "./pages/page-payment-form";
import {MerchantProvider} from "./context/merchant-context";
import {AppProvider} from "./context/app-context";
import PagePayComplete from "./pages/page-pay-complete";
import PagePaid from "./pages/page-paid";
import PageAddCard from "./pages/page-add-card";
import PageRecurringConfirmation from "./pages/page-recurring-confirmation";
import PageRecurringCancel from "./pages/page-recurring-cancel";
import PageCancelled from "./pages/page-cancelled";
import packages from '../package.json';

function App() {

    console.log('ePayment version ' + packages.version);

    return (
        <AppProvider>
            <MerchantProvider>
                <div id="app" className="container mx-auto">
                    <Router>
                        <Switch>
                            <Route path="/customer/billing/:paymentId">
                                <PageSinglePayment/>
                            </Route>
                            <Route path="/recurring/cancel/confirmPage/:paymentId">
                                <PageRecurringCancel/>
                            </Route>
                            <Route path="/recurring/confirmation">
                                <PageRecurringConfirmation/>
                            </Route>
                            <Route path="/recurring/:paymentId">
                                <PageRecurringPayment/>
                            </Route>
                            <Route path="/customer/info">
                                <PageCustomerInfo/>
                            </Route>
                            <Route path="/customer/pay">
                                <PagePaymentForm/>
                            </Route>
                            <Route path="/billing/complete">
                                <PagePayComplete/>
                            </Route>
                            <Route path="/billing/paid">
                                <PagePaid/>
                            </Route>
                            <Route path="/billing/cancelled">
                                <PageCancelled/>
                            </Route>
                            <Route path="/customer/card">
                                <PageAddCard/>
                            </Route>
                            <Route path="*">
                                <PageNotFound/>
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </MerchantProvider>
        </AppProvider>
    );
}

export default App;
