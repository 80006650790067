import React from 'react'

const defaultState = {
    merchantId: null,
    paymentId: null,
    customerId: null
};

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

function appReducer(state, {type, payload}) {
    switch (type) {
        case 'setAppInfo': {
            return Object.assign({}, state, payload);
        }
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }
    }
}

function AppProvider({children}) {

    const [state, dispatch] = React.useReducer(appReducer, defaultState)
    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

function useAppState() {

    const context = React.useContext(AppStateContext)
    if (context === undefined) {
        throw new Error('useAppState must be used within a AppProvider')
    }
    return context
}

function useAppDispatch() {
    const context = React.useContext(AppDispatchContext)
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a CountProvider')
    }
    return context
}

export {AppProvider, useAppDispatch, useAppState}
