import React from 'react';


const ButtonInvoice = ({text = "View Invoice Details", orderId}) => {

    const openReceipt = () => {
        const url = `${process.env.REACT_APP_CLOVER_RECEIPT_URL}/${orderId}`;
        window.open(url);
    };

    return <div className="py-4 text-xl text-center">
        <button
            type="button"
            onClick={openReceipt}
            className="w-full bg-transparent hover:bg-green-clover text-green-clover font-semibold hover:text-white py-1 px-3 border border-green-clover hover:border-transparent rounded">
            {text}
        </button>
        <span className="text-sm text-green-clover">Opens new window</span>
    </div>
};

export default ButtonInvoice;
