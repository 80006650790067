import http from '../libs/http';

export const getBillingDetails = ({merchantId, billingId}) => {

    const endpoint = `/v1/merchants/${merchantId}/payment/${billingId}`;
    return http({endpoint});
};

export const updateBilling = ({id, merchantId, status}) => {

    // TODO: remove the slash!
    const endpoint = '/pay/update/';
    return http({method: 'PUT', endpoint, body: {id, merchantId, status}});
};

export const sendReceipt = (merchantId, paymentId) => {

    const endpoint = '/pay/receipt/';
    return http({method: 'POST', endpoint, body: {merchantId, paymentId}});
};

export const cancelRecurring = (merchantId, paymentId, customerId) => {
    const endpoint = '/recurring/cancel';

    const payload = {
        merchantId,
        payRequestId: paymentId,
        customerId
    };

    return http({ method: 'POST', endpoint, body: payload });
}
