import React, {useEffect, useState} from 'react';
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import MainLayout from "../layout/main-layout";
import {useMerchantState} from "../context/merchant-context";
import {getUrlParams} from "../libs/utils";
import {getBillingDetails} from "../services/billing-service";
import {getOrderDetails} from "../services/order-service";
import TextMoney from "../components/text-money";
import ButtonInvoice from "../components/button-invoice";
import Spinner from "../components/spinner";

const PageCancelled = () => {

    const [order, setOrder] = useState({total: 0, id: null});
    const merchant = useMerchantState();
    const {merchant: merchantId, payment: paymentId, customer: customerId} = getUrlParams();

    if (!merchantId || !paymentId || !customerId) {
        throw Error('Invalid query params!');
    }

    useEffect(() => {

        const fetchOrder = async () => {

            const payment = await getBillingDetails({merchantId, billingId: paymentId});
            const orderData = await getOrderDetails({merchantId, orderId: payment.order_id})
            setOrder(orderData);
        };

        fetchOrder();
        /* eslint-disable-next-line */
    }, [order.id]);

    return <MainLayout>
        <Header/>
        <SubHeader merchant={merchant}/>
        <div className="my-5">
            {order.id ?
                <div className="text-center">
                    <h1 className="text-2xl">
                        Payment ID: {paymentId}
                    </h1>
                    <div className="text-xl">
                        Order ID: {order.id}
                    </div>
                    <div className="text-3xl">
                        Cancelled
                    </div>
                    <ButtonInvoice text="View Invoice" orderId={order.id}/>
                </div>
                :
                <div className="w-full inline-flex justify-center items-center">
                    <Spinner/>
                </div>
            }
        </div>
        <div className="my-4 border-t border-gray-400"/>
        <div className="text-center text-gray-600 text-sm">
            Invoice has been cancelled.
            For any concern please contact <a className="text-green-clover"
                                              href="mailto:support@epayapp.co">support@epayapp.co</a>
        </div>
    </MainLayout>
};

export default PageCancelled;
