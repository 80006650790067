import React from 'react';
import {Link} from "react-router-dom";

const ButtonProfile = ({text = 'Button Profile', customer, payment, merchant}) => {

    return <div className="py-4 text-xl text-center">
        <Link to={`/customer/info?customer=${customer}&payment=${payment}&merchant=${merchant}`}>
            <button className="w-full bg-green-clover font-semibold text-white py-1 px-3 border rounded">
                {text}
            </button>
        </Link>
    </div>
};

export default ButtonProfile;
