import React, {useEffect} from 'react';
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import {getUrlParams} from "../libs/utils";
import MainLayout from "../layout/main-layout";
import {useMerchantState} from "../context/merchant-context";
import {getOrderDetails} from "../services/order-service";
import {getBillingDetails} from "../services/billing-service";
import {useOrder} from "../hooks/use-order";
import {useBilling} from "../hooks/use-billing";
import InvoiceDetails from "../components/invoice-details";
import Spinner from "../components/spinner";
import ButtonInvoice from "../components/button-invoice";

const PageRecurringConfirmation = () => {

    const {...merchant} = useMerchantState();
    const {order, setOrder} = useOrder({});
    const {billing, setBilling} = useBilling({});
    const {merchant: merchantId, payment: paymentId} = getUrlParams();

    useEffect(() => {
        const loadInfo = async () => {
            const billingDetails = await getBillingDetails({merchantId, billingId: paymentId});
            setBilling(billingDetails);

            const orderDetails = await getOrderDetails({merchantId, orderId: billingDetails.order_id});
            setOrder(orderDetails);
        };

        loadInfo();
        /* eslint-disable-next-line */
    }, []);

    return <MainLayout>
        <Header/>
        <SubHeader merchant={merchant}/>
        <div className="text-xl mt-2 p-3">Recurring payment has been setup.</div>
        {billing.id ? <InvoiceDetails order={order} billingDetail={billing}/> :
            <div className="w-full my-4 inline-flex justify-center"><Spinner/></div>}
        <div className="my-2">For any questions please contact <a href="mailto:support@epayapp.co" className="text-green-clover">suppoprt@epayapp.co</a></div>
        <hr/>
        <ButtonInvoice orderId={order.id}/>
    </MainLayout>
};

export default PageRecurringConfirmation;
