import React, {useState} from 'react';
import Header from "../components/header";
import MainLayout from "../layout/main-layout";
import {useMerchantState} from "../context/merchant-context";
import SubHeader from "../components/sub-header";
import FormCreditCard from "../components/form-credit-card";
import {getUrlParams} from "../libs/utils";
import {useHistory} from "react-router-dom";
import ButtonSaveCard from "../components/button-save-card";
import SavedCardInfo from "../components/saved-card-info";

function PageAddCard() {

    const merchant = useMerchantState();
    const [validForm, setValidForm] = useState();
    const [clover, setCloverInstance] = useState();
    const history = useHistory();
    const {merchant: merchantId, payment: paymentId, customer: customerId} = getUrlParams();

    return (
        <MainLayout>
            <Header/>
            <SubHeader merchant={merchant}/>
            <div className="mx-3">
                <h1 className="text-2xl">
                    Save card info
                </h1>
                Our system doesn't store full digit of the card number.
                We exchange the card information on Clover with a token to be used for further transaction.
            </div>
            <SavedCardInfo customerId={customerId} paymentId={paymentId}/>
            <FormCreditCard
                setValidForm={setValidForm}
                setCloverInstance={setCloverInstance}
                paymentId={paymentId}
                merchantId={merchantId}
                customerId={customerId}
                history={history}
                eCommerceKey={merchant.eCommerceKey}/>
            <ButtonSaveCard
                clover={clover}
                merchantId={merchantId}
                paymentId={paymentId}
                customerId={customerId}
                validForm={validForm}/>
        </MainLayout>
    )
}

export default PageAddCard;
