import React, {useEffect, useState} from 'react';
import {getSavedCard} from "../services/customer-service";

const SavedCardInfo = ({customerId, paymentId}) => {

    const [last4, setLast4] = useState(null);
    const [brand, setBrand] = useState(null);

    useEffect(() => {

        const getCard = async () => {
            const card = await getSavedCard(customerId, paymentId);
            if (card.last4) {
                setLast4(card.last4);
                setBrand(card.card_type);
            }
        }
        getCard();
    }, [customerId, last4, paymentId, brand]);

    return (last4 ?
        <div className="my-2 p-3 bg-yellow-200 border border-gray-400">
            {String(brand).toUpperCase()} card with last 4 digits {last4} already added. Replace it by entering another payment below.
        </div>
        : null
    )
}

export default SavedCardInfo;
