import React from 'react';
import Header from "../components/header";

const PageNotFound = () => {
    return <div className="text-center">
        <Header />
        <div className="text-5xl font-extrabold">404</div>
        <h1 className="text-5xl font-extrabold">Page Not Found!</h1>
        <div className="my-2">For any questions please contact <a href="mailto:support@epayapp.co" className="text-green-clover">suppoprt@epayapp.co</a></div>
    </div>
}

export default PageNotFound;
