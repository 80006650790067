import React, {Fragment} from 'react';
import TextMoney from "./text-money";

const tableRow = ({title, content}) => (
    <tr>
        <td className="pr-2 py-2 font-semibold text-right">{title} </td>
        <td className="px-2 py-2">{content}</td>
    </tr>
);

const singleInvoice = (order) => {

    return tableRow({
        title: 'Request Payment:',
        content: <TextMoney price={order.total / 100} currencyCode={order.currency}/>
    });
};

const recurringInvoice = (order, billing) => {

    return ([
        tableRow({
            title: 'Billing started from:',
            content: billing.start_date
        }),
        tableRow({
            title: 'Repeats period:',
            content: billing.repeats_period
        }),
        tableRow({
            title: 'Number of cycles:',
            content: billing.repeats_cycle
        }),
        tableRow({
            title: 'Billing amount:',
            content: <TextMoney price={(order.total || 0) / 100} currencyCode={order.currency}/>
        }),
        tableRow({
            title: 'Total billing amount:',
            content: <TextMoney price={((order.total || 0) * billing.repeats_cycle) / 100} currencyCode={order.currency}/>
        }),
    ])

};

const InvoiceDetails = ({order, billingDetail = {}}) => {

    return <div className="py-4 px-3">
        <table className="table-auto">
            <tbody>
            {
                billingDetail.is_recurring ?
                    recurringInvoice(order, billingDetail)
                        .map((row, index) => (<Fragment key={index}>{row}</Fragment>)) :
                    singleInvoice(order)
            }
            {tableRow({title: 'Note:', content: order.note || '-'})}
            </tbody>
        </table>
    </div>
};

export default InvoiceDetails;
