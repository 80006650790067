import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import Spinner from "./spinner";
import {saveCustomerCard} from "../services/customer-service";

const ButtonSaveCard = ({validForm, customerId, paymentId, clover, merchantId}) => {

    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState(false);

    const history = useHistory();
    const saveCard = async () => {
        setUpdating(true);
        setError(false);
        try {
            const {errors, card, token} = await clover.createToken();
            if (errors) {
                throw Error(errors);
            }
            const payload = {
                merchantId,
                card,
                token,
                paymentId
            }
            await saveCustomerCard(customerId, payload);

            const queries = `customer=${customerId}&payment=${paymentId}&merchant=${merchantId}`;
            const url = `/recurring/confirmation?${queries}`;
            history.push(url);
        } catch (err) {
            setError(true);
            console.error(err);
        }
        setUpdating(false);
    };

    return <div className="pb-4 pt-1 text-xl">
        {updating ? <div className="w-full inline-flex justify-center items-center"><Spinner/></div> :
            <div>
                {error ?
                    <div className="text-sm mx-2 my-2 text-red-800">Error on saving card. Please try again or contact customer support <a className="text-green-clover" href="mailto:support@epayapp.co">support@epayapp.co</a>.</div> :
                    null
                }
                <button
                    disabled={!validForm}
                    onClick={saveCard}
                    className={`w-full ${validForm ? 'bg-green-clover' : 'bg-gray-400'} font-semibold text-white py-1 px-3 border rounded`}>
                    Save Card
                </button>
            </div>
        }
    </div>
}

export default ButtonSaveCard;
