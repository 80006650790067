import React, {useState} from 'react';
import Spinner from "./spinner";
import {cancelRecurring} from "../services/billing-service";

const ButtonCancelRecurring = ({merchantId, paymentId, customerId, setCancelled}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const cancelPayment = async () => {
        if (window.confirm('Are you sure to cancel the payment?')) {
            setLoading(true);
            setError(false);
            try {
                const res = await cancelRecurring(merchantId, paymentId, customerId);
                // the server returning string....
                if (typeof res === 'string') {
                    throw Error(res);
                }

                setCancelled(true);
            } catch (err) {
                setError(true);
            }
            setLoading(false);
        }
    };

    const btnClassName = 'w-full bg-red-600 hover:bg-red-800 text-white font-semibold hover:text-white py-1 px-3  hover:border-transparent rounded';
    return (
        loading ?
            <button
                disabled=""
                className="w-full inline-flex items-center justify-center 'bg-gray-400'r py-1 px-3 border rounded">
                <Spinner/>
            </button> :
            <>
                <div
                    onClick={cancelPayment}
                    className="py-4 text-xl text-center">
                    <button className={btnClassName}>
                        Cancel Recurring Payment
                    </button>
                </div>
                {error ? <div className="text-red-600">Sorry there was an error for generating your cancellation email. Please contact customer service to get help.</div> : null}
            </>)
};

export default ButtonCancelRecurring;
