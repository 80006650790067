import {useState} from 'react';

export const useBilling = () => {

    const [billing, setBilling] = useState({});

    return {
        billing,
        setBilling
    }
};


