import React from 'react';

const getLocaleByCurrency = (currencyCode) => {
    const symbols = {
        usd: 'en-US'
    };
    return symbols[String(currencyCode).toLowerCase()] || 'en-US';
};

const TextMoney = ({ currencyCode = 'usd', price = 0 }) => {
    const locale = getLocaleByCurrency(currencyCode);
    return (
        <>
            {new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).format(price)}
        </>
    );
};

export default TextMoney;
