import React from 'react';
import Spinner from "./spinner";

const basicInputStyle = {
    'width': '100%',
    'font-size': '20px',
    'line-height': '24px',
    'height': '40px',
    'border': '1px gray solid',
    'padding': '3px 5px',
    'border-radius': '0.25rem'
};

const styles = {
    'card-number input': basicInputStyle,
    'card-date input': basicInputStyle,
    'card-cvv input': {
        ...basicInputStyle
    },
    'card-postal-code input': basicInputStyle,
    'card-number img.brand': {
        'padding': '5px'
    }
};

class FormCreditCard extends React.Component {

    elements = null;
    clover = null;
    events = ['change', 'blur'];
    card = {
        number: null,
        date: null,
        cvv: null,
        postalCode: null
    };

    // we use state for UI update
    state = {
        loading: true,
        processing: false,
        validForm: false,
        CARD_NUMBER: {touched: false, error: null},
        CARD_DATE: {touched: false, error: null},
        CARD_CVV: {touched: false, error: null},
        CARD_POSTAL_CODE: {touched: false, error: null}
    };

    scriptId = 'CloverSDK';

    initForm = () => {
        if (!this.props.eCommerceKey) {
            throw Error('Invalid eCommerceKey!');
        }

        this.setState(({loading: true}), () => {
            this.clover = new window.Clover(this.props.eCommerceKey);
            this.props.setCloverInstance(this.clover);
            this.elements = this.clover.elements();
            const card = {
                number: this.elements.create('CARD_NUMBER', styles),
                cvv: this.elements.create('CARD_CVV', styles),
                date: this.elements.create('CARD_DATE', styles),
                postalCode: this.elements.create('CARD_POSTAL_CODE', styles)
            }
            this.card = Object.assign({}, card);
            this.setState(({loading: false}), () => {
                this.mountForm();
                this.setFormListener();
            });
        });

    }

    mountForm = () => {
        this.card.number.mount('#card-number');
        this.card.date.mount('#card-date');
        this.card.cvv.mount('#card-cvv');
        this.card.postalCode.mount('#card-postal-code');
    }

    setFormListener = () => {
        Object.keys(this.card).forEach((key) => {
            this.events.forEach((event) => {
                this.card[key].addEventListener(event, this.setCardState);
            })
        });
    }

    setCardState = (event) => {
        // the event contains 2 prop ('touched' AND 'error')
        Object.keys(event).forEach((key) => {
            if (event[key].touched) {
                this.setState(() => ({[key]: event[key]}));
            }
        });
        this.validateForm();
    }

    removeFormListener = () => {
        Object.keys(this.card).forEach((key) => {
            this.events.forEach((event) => {
                if (this.card[key]) {
                    this.card[key].removeEventListener(event, this.setCardState);
                }
            })
        });
    }

    isValidForm() {
        let validForm = true;
        Object.keys(this.state).forEach((key) => {
            for (const childProp in this.state[key]) {
                if (childProp === 'error') {
                    validForm = false;
                }
            }
        });
        return validForm;
    }

    validateForm() {
        const validForm = this.isValidForm();
        this.props.setValidForm(validForm);
    }

    componentWillUnmount() {
        this.removeFormListener();
        Array.from(document.getElementsByClassName('clover-footer')).forEach((dom) => dom.remove());
        Array.from(document.getElementsByTagName('iframe')).forEach((dom) => dom.parentNode.remove());
        this.clover = null;
    }

    componentDidMount() {
        this.initForm();
    }

    render() {
        return (<>
            {this.state.loading ?
                <div className="w-full inline-flex items-center justify-center my-5"><Spinner/></div> : null}
            <form>
                <div className="px-4 pt-4 pb-2">
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full sm:w-3/4 pr-0 sm:pr-2" style={{height: '70px'}}>
                            <div id="card-number" className="clover-input">
                            </div>
                            <div className="text-red-900" role="alert">
                                {this.state.CARD_NUMBER.error ? 'Invalid card' : null}
                            </div>
                        </div>
                        <div className="w-full sm:w-1/4 pl-0 sm:pl-2" style={{height: '70px'}}>
                            <div id="card-cvv" className="clover-input"/>
                            <div className="text-red-900" role="alert">
                                {this.state.CARD_CVV.error ? 'Invalid CVV' : null}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full sm:w-1/3 pr-0 sm:pr-2" style={{height: '70px'}}>
                            <div id="card-date" className="clover-input"/>
                            <div className="text-red-900" role="alert">
                                {this.state.CARD_DATE.error ? 'Invalid date' : null}
                            </div>
                        </div>
                        <div className="w-full sm:w-2/3 pl-0 sm:pl-2" style={{height: '70px'}}>
                            <div id="card-postal-code" className="clover-input"/>
                            <div className="text-red-900" role="alert">
                                {this.state.CARD_POSTAL_CODE.error ? 'Invalid ZIP' : null}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>);
    }
}

FormCreditCard.defaultProps = {
    validForm: false,
    paymentId: '',
    merchantId: '',
    customerId: '',
    history: null,
    eCommerceKey: '',
    setCloverInstance: () => {
    },
    setValidForm: () => {
    }
}

export default FormCreditCard;
