import React from 'react';
import Spinner from "./spinner";

const FormCustomerInfo = ({customer, handleInputChange}) => {
    return (
        customer.id ? <div className="px-3 py-4">
                <form className="w-full">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-first-name">
                                First Name
                            </label>
                            <input
                                type="text"
                                name="firstName"
                                value={customer.firstName}
                                onChange={(event) => handleInputChange('firstName', event)}
                                className="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name"/>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-last-name">
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="lastName"
                                value={customer.lastName}
                                onChange={(event) => handleInputChange('lastName', event)}
                                className="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-address">
                                Address
                            </label>
                            <input
                                type="text"
                                name="address1"
                                value={customer.address1}
                                onChange={(event) => handleInputChange('address1', event)}
                                className="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-address"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-city">
                                City
                            </label>
                            <input
                                type="text"
                                name="city"
                                value={customer.city}
                                onChange={(event) => handleInputChange('city', event)}
                                className="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-city"/>
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <div className="relative">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                       htmlFor="grid-state">
                                    State
                                </label>
                                <input
                                    type="text"
                                    name="state"
                                    value={customer.state}
                                    onChange={(event) => handleInputChange('state', event)}
                                    className="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-state"/>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="grid-zip">
                                Zip
                            </label>
                            <input
                                type="text"
                                name="zip"
                                value={customer.zip}
                                onChange={(event) => handleInputChange('zip', event)}
                                className="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-zip"/>
                        </div>
                    </div>
                </form>
            </div> :
            <div className="w-full inline-flex justify-center items-center">
                <Spinner/>
            </div>
    )
};

export default FormCustomerInfo;
