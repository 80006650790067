import React, {useEffect} from 'react';
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import InvoiceDetails from "../components/invoice-details";
import ButtonInvoice from "../components/button-invoice";
import ButtonProfile from "../components/button-profile";
import MainLayout from "../layout/main-layout";
import {useMerchantState} from "../context/merchant-context";
import {useOrder} from "../hooks/use-order";
import {getOrderDetails} from "../services/order-service";
import {getBillingDetails, updateBilling} from "../services/billing-service";
import {getUrlParams} from "../libs/utils";
import {useParams} from "react-router-dom";
import {useBilling} from "../hooks/use-billing";
import Spinner from "../components/spinner";

const PageRecurringPayment = () => {

    const {...merchant} = useMerchantState();
    const {order, setOrder} = useOrder({});
    const {billing, setBilling} = useBilling({});
    const {merchant: merchantId, order: orderId, customer: customerId} = getUrlParams();
    const params = useParams();
    if (!params.paymentId) {
        // TODO: do something here! return to page not found / payment not found
    }

    useEffect(() => {
        const loadInfo = async () => {
            const orderDetails = await getOrderDetails({merchantId, orderId});
            setOrder(orderDetails);
            const billingDetails = await getBillingDetails({merchantId, billingId: params.paymentId});
            if (billingDetails.status === 'completed') {
                // const url = `/billing/paid?customer=${customerId}&payment=${params.paymentId}&merchant=${merchantId}`;
                // return history.push(url);
            } else {
                setBilling(billingDetails);
                await updateStatus();
            }
        };

        const updateStatus = async () => {
            await updateBilling({id: params.paymentId, merchantId, status: 'viewed'});
        };

        loadInfo();
        /* eslint-disable-next-line */
    }, []);

    return <MainLayout>
        <Header/>
        <SubHeader merchant={merchant}/>
        {billing.id ? <InvoiceDetails order={order} billingDetail={billing}/> :
            <div className="w-full my-4 inline-flex justify-center"><Spinner/></div>}
        <ButtonInvoice orderId={orderId}/>
        <ButtonProfile
            text="Add Payment Info"
            customer={customerId}
            payment={params.paymentId}
            merchant={merchantId}/>
    </MainLayout>
};

export default PageRecurringPayment;
