import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {updateCustomerAddress} from "../services/customer-service";
import Spinner from "./spinner";

const ButtonEnterCard = ({validCustomer, customer, paymentId, merchantId, recurring = false}) => {

    const [updating, setUpdating] = useState(false);
    const history = useHistory();
    const updateCustomer = async () => {
        setUpdating(true);
        try {
            const {address1, city, state, country, zip} = customer;
            await updateCustomerAddress({merchantId, customerId: customer.id}, {address1, city, state, country, zip});

            const queries = `customer=${customer.id}&payment=${paymentId}&merchant=${merchantId}`;
            const url = `${recurring ? '/customer/card' : '/customer/pay'}?${queries}`;
            history.push(url);
        } catch (err) {}
    };

    return <div className="py-4 text-xl text-center">
        {updating ? <div className="w-full inline-flex justify-center items-center"><Spinner/></div> :
            <button
                disabled={!validCustomer}
                onClick={updateCustomer}
                className={`w-full ${validCustomer ? 'bg-green-clover' : 'bg-gray-400'} font-semibold text-white py-1 px-3 border rounded`}>
                Enter Card
            </button>
        }
    </div>
}

export default ButtonEnterCard;
