import React from 'react';

const Header = () => {

    return <div className="bg-green-clover text-white py-4 px-3 text-xl">
        ePayment
    </div>
};

export default Header;
