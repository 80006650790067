import React, {useEffect, useState} from 'react';
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import MainLayout from "../layout/main-layout";
import {useMerchantState} from "../context/merchant-context";
import {getOrderDetails} from "../services/order-service";
import {getUrlParams} from "../libs/utils";
import {getBillingDetails} from "../services/billing-service";
import TextMoney from "../components/text-money";
import ButtonInvoice from "../components/button-invoice";
import Spinner from "../components/spinner";
import {useHistory} from "react-router-dom";

const PagePayComplete = () => {

    const [order, setOrder] = useState({total: 0, id: null});
    const merchant = useMerchantState();
    const {merchant: merchantId, payment: paymentId, customer: customerId} = getUrlParams();

    if (!merchantId || !paymentId || !customerId) {
        throw Error('Invalid query params!');
    }

    // prevent back button
    const history = useHistory();
    window.addEventListener("popstate", e => {
        // Nope, go back to your page
        history.go(1);
    });

    useEffect(() => {

        const fetchOrder = async () => {

            const payment = await getBillingDetails({merchantId, billingId: paymentId});
            const orderData = await getOrderDetails({merchantId, orderId: payment.order_id})
            setOrder(orderData);
        };

        fetchOrder();

        /* eslint-disable-next-line */
    }, [order.id]);
    return <MainLayout>
        <Header/>
        <SubHeader merchant={merchant}/>
        <div className="my-5">
            {order.id ?
                <div className="text-center">
                    <h1 className="text-3xl">
                        Payment ID: {paymentId}
                    </h1>
                    <div className="text-2xl">
                        <TextMoney price={order.total / 100} currencyCode={order.currency}/> Sent
                    </div>
                    <ButtonInvoice text="View Invoice" orderId={order.id}/>
                </div>
                :
                <div className="w-full inline-flex justify-center items-center">
                    <Spinner/>
                </div>
            }
        </div>
        <div className="my-4 border-t border-gray-400"/>
        <div className="text-center text-gray-600 text-sm">
            Payment complete, you can close this page manually.
            For help, email <a className="text-green-clover" href="mailto:support@epayapp.co">support@epayapp.co</a>
        </div>
    </MainLayout>
};

export default PagePayComplete;
