import React, {useEffect} from 'react';
import Header from "../components/header";
import SubHeader from "../components/sub-header";
import InvoiceDetails from "../components/invoice-details";
import ButtonInvoice from "../components/button-invoice";
import ButtonProfile from "../components/button-profile";
import {getUrlParams} from "../libs/utils";
import {useOrder} from "../hooks/use-order";
import {getOrderDetails} from "../services/order-service";
import {useHistory, useParams} from 'react-router-dom';
import {getBillingDetails, updateBilling} from "../services/billing-service";
import {useBilling} from "../hooks/use-billing";
import {useMerchantState} from "../context/merchant-context";
import MainLayout from "../layout/main-layout";

// TODO: why this page re-render multiple times!
const PageSinglePayment = () => {

    const {...merchant} = useMerchantState();
    const {order, setOrder} = useOrder({});
    const {setBilling} = useBilling({});
    const history = useHistory();
    const {merchant: merchantId, order: orderId, customer: customerId} = getUrlParams();

    const params = useParams();
    if (!params.paymentId) {
        // TODO: do something here! return to page not found / payment not found
    }

    useEffect(() => {
        const loadInfo = async () => {
            const orderDetails = await getOrderDetails({merchantId, orderId});
            setOrder(orderDetails);
            const billingDetails = await getBillingDetails({merchantId, billingId: params.paymentId});
            if (billingDetails.status === 'completed' || billingDetails.status === 'paid') {
                const url = `/billing/paid?customer=${customerId}&payment=${params.paymentId}&merchant=${merchantId}`;
                return history.push(url);
            } else {
                setBilling(billingDetails);
                await updateStatus();
            }
        };

        const updateStatus = async () => {
            await updateBilling({id:params.paymentId, merchantId, status:'viewed'});
        };

        loadInfo();
        /* eslint-disable-next-line */
    }, []);

    return (<MainLayout>
        <Header/>
        <SubHeader merchant={merchant}/>
        <InvoiceDetails order={order}/>
        <ButtonInvoice orderId={orderId}/>
        <ButtonProfile
            text="Pay With Credit/Debit Card"
            customer={customerId}
            payment={params.paymentId}
            merchant={merchantId}/>
    </MainLayout>);
};

export default PageSinglePayment;
