import {useEffect} from 'react';
import {getMerchant, setMerchantId, useMerchantDispatch, useMerchantState} from "../context/merchant-context";
import {getUrlParams} from "../libs/utils";

const MainLayout = ({children}) => {

    const merchantDispatch = useMerchantDispatch();
    const merchant = useMerchantState();
    let {merchant: merchantId} = getUrlParams();

    if (!merchantId) {
        merchantId = localStorage.getItem('merchantId');
    }
    useEffect(() => {

        const loadMerchant = async () => {
            setMerchantId(merchantDispatch, merchantId);
            await getMerchant(merchantDispatch, merchantId);
        }

        loadMerchant();
    }, [merchantDispatch, merchantId]);

    return (merchant.id ? children : null)
};

export default MainLayout;
